import React from "react"
import Layout, { SimpleLayout } from "../components/layout"
import Hero from "../components/hero"
import { FooterWrapper } from "../components/footer"
import styles from "./about.module.css"

import BusinessCard from "../components/business-card"
import imageAnna from "../images/team/anna.jpg"
import imageMorgan from "../images/team/morgan.jpg"
import imageMike from "../images/team/mike.jpg"

import imageBpp from "../images/team/bpp-university.png"
import imageUh from "../images/team/uh.png"
import imageUos from "../images/team/uos.png"

import imageSales from "../images/team/sales.png"
import imageUnity from "../images/team/unity.png"

import imageCircle95 from "../images/about/circle95.png"

import imageBarts from "../images/clients/barts_transparent.png"

import Clients from "../components/clients"
import Connected from "../components/connected"

import imageDownload from "../images/about/download.png"
import imageDownloadExt from "../images/about/download_ext.png"

import imageBackground from "../images/about/about-background.png"

export default () => {
  return (
    <FooterWrapper>
      <div className={styles.about}>
        <Layout image={imageBackground}>
          <Hero
            center
            hero="Delivering real learning"
            herosub="Our products are driven by our mission, values and team"
          />
          <div className={styles.boxes}>
            <div className={styles.box} style={{ background: "#0066C4" }}>
              <h2>We are working for</h2>
              <h1>Turning virtual experiences into real learning</h1>
              <p>
                Our mission is to help more people learn how to save lives.
                Immersive technology can empower organisations to do this.
                Immersive training provides an opportunity for your staff to
                train more frequently, at a fraction of the cost of offline
                training.
              </p>
            </div>
            <div className={styles.box} style={{ background: "#634594" }}>
              <h2>We believe in</h2>
              <h1>Creating access to immersive learning for the world </h1>
              <p>
                We believe that everyone should have access to various immersive
                scenario training, no matter where they are. Working with
                organisations globally, we allow organisations to incorporate
                latest technology into their training methods, in an easy and
                accessible way.
              </p>
            </div>
          </div>
        </Layout>

        <Team />
        <Validation />
        <Partners />
        <Jobs />
        <SimpleLayout style={{ background: "white" }}>
          <Connected />
        </SimpleLayout>
      </div>
    </FooterWrapper>
  )
}

const Validation = () => (
  <div className={styles.validation}>
    <div className={styles.vbox} style={{ background: "#61AAFF" }}>
      <div className={styles.vboxinner}>
        <h2>The need</h2>
        <h1>Where current methods fail </h1>
        <img src={imageCircle95} alt="circle 95" />
        <h3 style={{ marginTop: "30px" }}>
          adults don’t have the skills and confidence to provide first aid in
          emergency situations
        </h3>
        <div className={styles.vbuttoncontainer}>
          <a
            target="_blank"
            href=" https://www.redcross.org.uk/about-us/news-and-media/media-centre/press-releases/press-release-new-research-on-adults-and-first-aid"
          >
            <span>Source</span> <img src={imageDownloadExt} alt="download" />
          </a>
        </div>
      </div>
    </div>
    <div
      className={styles.vbox}
      style={{ background: "var(--main-orange)", justifyContent: "flex-start" }}
    >
      <div className={styles.vboxinner}>
        <h2>Validation</h2>
        <h1>Why VR is needed</h1>
        <hr style={{ width: "62px" }} />
        <h1>
          Scoring better than offline training for ease of use, content and
          overall experience
        </h1>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "26px",
          }}
        >
          <div style={{ width: "260px", margin: "auto" }}>
            <img src={imageBarts} alt="barts" />
          </div>
          <h3 style={{ textAlign: "left" }}>
            Findings by an academic study led by Barts Institute, Queen Mary
            University of London
          </h3>
        </div>
        <div style={{ marginTop: "36px" }}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.dropbox.com/s/bj9ojm5kss6tv2a/Dual_Good_Health_Case_Study_Research.pdf?dl=0"
            className={styles.vbutton}
          >
            <span>Download study</span>{" "}
            <img src={imageDownload} alt="download" />
          </a>
        </div>
      </div>
    </div>
  </div>
)

const Jobs = () => (
  <SimpleLayout style={{ background: "white", padding: "20px" }}>
    <div className={styles.jobs}>
      <h1>Job opportunities</h1>
      <h2>Our current job openings</h2>

      <div style={{ marginTop: "40px" }}>
        <img src={imageSales} alt="sales" />
        <h1>Sales Executive</h1>
        <p>
          In this role you’ll own the sales process from end to end, by
          communicating with new and existing leads and delivering an
          appropriate business development strategy. This is an excellent
          opportunity to gain ownership of a large part of customer pipeline,
          and make a large impact for the company, as well as its international
          customer base.
        </p>
        <div className={styles.buttoncontainer}>
          <a href="mailto:anna@dualgoodhealth.com" className={styles.button}>
            APPLY
          </a>
        </div>
      </div>
      <hr style={{ margin: "50px 10%" }} />
      <div>
        <img src={imageUnity} alt="unity" />
        <h1>Unity developer</h1>
        <p>
          You’ll be supporting the Technical director to create engaging Virtual
          Reality products. You will be welcome to contribute with product ideas
          and implementation methods, so passion for immersive training is a
          must. Expert level of Unity engine is essential for this role, and
          experience in VR projects is desired.
        </p>
        <div className={styles.buttoncontainer}>
          <a href="mailto:anna@dualgoodhealth.com" className={styles.button}>
            APPLY
          </a>
        </div>
      </div>
    </div>
  </SimpleLayout>
)

const Partners = () => (
  <SimpleLayout style={{ background: "white" }}>
    <Clients partners>
      <h1>They trusted us</h1>
      <h2>Our clients and partnerships</h2>
    </Clients>
  </SimpleLayout>
)

const Team = () => {
  let cardHeight = "520px"
  return (
    <SimpleLayout style={{ background: "#f8f7fe" }}>
      <div className={styles.team}>
        <h1>Leadership team</h1>
        <div className="card-feed" style={{ marginTop: "40px" }}>
          <BusinessCard
            title="Anna Stoilova"
            cardImage={imageAnna}
            height={cardHeight}
            linkedin="https://www.linkedin.com/in/annastoilova/"
          >
            <h2>CEO</h2>
            <p>
              Anna has also worked in the immersive technologies space since
              2015, creating VR products that engage. She also has experience
              producing digital products for national and international brands.
            </p>
          </BusinessCard>
          <BusinessCard
            title="Morgan Page"
            cardImage={imageMorgan}
            height={cardHeight}
            linkedin="https://www.linkedin.com/in/morganjpage/"
            twitter="https://twitter.com/PlatoEvolved"
          >
            <h2>CTO</h2>
            <p>
              Morgan is an experienced software developer with 20 years of
              experience and has created 10 games, with one of them having more
              than 1.3 million downloads. He has been working in the VR and
              immersive technologies since 2015.
            </p>
          </BusinessCard>
          <BusinessCard
            title="Mike Gadd"
            cardImage={imageMike}
            height={cardHeight}
          >
            <h2>Commercial Advisor</h2>
            <p>
              Mike has worked within SaaS start-ups for the last 7 years,
              helping sales teams scale in line with C-Level and investor
              expectations. He specialises in building out commercial teams, and
              refining sales processes.
            </p>
          </BusinessCard>
        </div>
        <h2 style={{ paddingTop: "40px" }}>Medical advisory board</h2>
        <h3>Our medical advisors come from</h3>
        <div className={styles.advisory}>
          <img src={imageBpp} alt="bpp" />
          <img src={imageUh} alt="uh" />
          <img src={imageUos} alt="uos" />
        </div>
      </div>
    </SimpleLayout>
  )
}
