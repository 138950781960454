import React from "react"
import styles from "./business-card.module.css"

import { FaLinkedin, FaTwitter } from "react-icons/fa"

export default props => (
  <div style={{ height: props.height || "300px" }} className={styles.card}>
    <img src={props.cardImage} style={{ marginBottom: "30px" }} alt="" />
    <h1>{props.title}</h1>
    {props.children}
    <div className={styles.buttoncontainer}>
      {props.linkedin && (
        <a target="_blank" rel="noopener noreferrer" href={props.linkedin}>
          <FaLinkedin />
        </a>
      )}
      {props.twitter && (
        <a target="_blank" rel="noopener noreferrer" href={props.twitter}>
          <FaTwitter />
        </a>
      )}
    </div>
  </div>
)
